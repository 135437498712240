<template>
  <div class="update-location">
    <navbar title="位置上传"> </navbar>
    <div class="main">
      <ul class="form">
        <!-- 模块mac -->
        <li class="bg form-item" v-if="type === 1">
          <van-row>
            <van-col span="6" class="title">模块MAC</van-col>
            <van-col span="18" class="mac">{{ form.moduleMac }}</van-col>
          </van-row>
        </li>
        <li
          class=" form-item model-box"
          :class="focusShow ? 'bg2' : 'bg'"
          v-else
        >
          <van-field
            v-model="inputMac"
            placeholder="请选择模块mac"
            @focus="expand"
            class="mac-input"
          />
          <div class="macs" v-if="focusShow">
            <div
              v-for="(item, index) in macArr"
              :key="index"
              class="mac-item"
              @click="selectMac(item)"
            >
              <span :class="form.moduleMac === item ? 'color' : ''">{{
                item
              }}</span>
              <img :src="ICON2" alt="" v-if="form.moduleMac === item" />
            </div>
            <div
              class="mac-prompt"
              v-if="macArr.length === 0 && inputMac === ''"
            >
              暂无数据
            </div>
            <div class="mac-prompt" v-else-if="inputMac && macArr.length === 0">
              暂无匹配项
            </div>
          </div>
        </li>
        <!-- 大楼 -->
        <li class="bg select-box  form-item">
          <van-field
            readonly
            clickable
            name="picker"
            :value="form.towerName"
            placeholder="请选择大楼"
            :right-icon="ICON3"
            @click="towerShow = true"
          />
          <van-popup
            v-model="towerShow"
            round
            position="bottom"
            class="popup-box"
          >
            <van-picker
              show-toolbar
              :columns="towerList"
              value-key="name"
              :default-index="towerIndex"
              visible-item-count="5"
              @confirm="selectTower"
              @cancel="towerShow = false"
            />
          </van-popup>
        </li>
        <!-- 楼层 -->
        <li class="bg select-box  form-item">
          <van-field
            readonly
            clickable
            name="picker"
            :value="form.floorName"
            placeholder="请选择楼层"
            :right-icon="ICON3"
            @click="floorEvent"
          />
          <van-popup
            v-model="floorShow"
            round
            position="bottom"
            class="popup-box"
          >
            <van-picker
              show-toolbar
              :columns="floorList"
              value-key="name"
              :default-index="floorIndex"
              visible-item-count="5"
              @confirm="selectFloor"
              @cancel="floorShow = false"
            />
          </van-popup>
        </li>
        <!-- 配电间 -->
        <li class="bg select-box  form-item">
          <van-field
            readonly
            clickable
            name="picker"
            :value="form.roomNumber"
            placeholder="请选择配电间"
            :right-icon="ICON3"
            @click="roomEvent"
          />
          <van-popup
            v-model="roomShow"
            round
            position="bottom"
            class="popup-box"
          >
            <van-picker
              show-toolbar
              :columns="roomList"
              value-key="alias"
              :default-index="roomIndex"
              visible-item-count="5"
              @confirm="selectRoom"
              @cancel="roomShow = false"
            />
          </van-popup>
        </li>
        <li class="form-item">
          <van-field
            v-model="form.roomName"
            placeholder="请输入配电间别名"
            class="bg input"
            maxlength="30"
            @blur="handleScroll"
          />
        </li>
        <li class="camera form-item">
          <div class="camera-box">
            <div class="image">
              <img
                :src="ICON1"
                alt=""
                class="img-icon"
                v-if="!returnType(form.outsideImage)"
                @click="mychooseImage(1)"
              />
              <img
                :src="outsideImage"
                class="photo"
                v-else
                @click="viewImagesEvent(0)"
              />
              <div
                class="del-img"
                v-if="returnType(form.outsideImage)"
                @click="delImage(1)"
              >
                <van-icon name="cross" />
              </div>
            </div>
            <div class="prompt">配电间外照片</div>
          </div>
          <div class="camera-box">
            <div class="image">
              <img
                :src="ICON1"
                class="img-icon"
                v-if="!returnType(form.indoorImage)"
                @click="mychooseImage(2)"
              />
              <img
                :src="indoorImage"
                class="photo"
                @click="viewImagesEvent(1)"
                v-else
              />
              <div
                class="del-img"
                v-if="returnType(form.indoorImage)"
                @click="delImage(2)"
              >
                <van-icon name="cross" />
              </div>
            </div>
            <div class="prompt">配电间内照片</div>
          </div>
        </li>
        <!-- 电箱 -->
        <li class="bg select-box  form-item">
          <van-field
            readonly
            clickable
            name="picker"
            :value="form.electricityNumber"
            placeholder="请选择电箱"
            :right-icon="ICON3"
            @click="boxEvent"
          />
          <van-popup
            v-model="electricityShow"
            round
            position="bottom"
            class="popup-box"
          >
            <van-picker
              show-toolbar
              :columns="electricityList"
              value-key="alias"
              :default-index="electricityIndex"
              visible-item-count="5"
              @confirm="selectBox"
              @cancel="electricityShow = false"
            />
          </van-popup>
        </li>
        <li class="form-item">
          <van-field
            v-model="form.electricityName"
            placeholder="请输入电箱别名"
            class="bg input"
            maxlength="30"
            @blur="handleScroll"
          />
        </li>
        <li class="camera form-item">
          <div class="camera-box">
            <!-- <van-uploader :upload-icon="ICON1" disabled /> -->
            <div class="image">
              <img
                :src="ICON1"
                alt=""
                class="img-icon"
                v-if="!returnType(form.boxOuterImage)"
                @click="mychooseImage(3)"
              />
              <img
                :src="boxOuterImage"
                class="photo"
                v-else
                @click="viewImagesEvent(2)"
              />
              <div
                class="del-img"
                v-if="returnType(form.boxOuterImage)"
                @click="delImage(3)"
              >
                <van-icon name="cross" />
              </div>
            </div>
            <div class="prompt">电箱外照片</div>
          </div>
          <div class="camera-box">
            <!-- <van-uploader :upload-icon="ICON1" disabled /> -->
            <div class="image">
              <img
                :src="ICON1"
                alt=""
                class="img-icon"
                v-if="!returnType(form.boxInsideImage)"
                @click="mychooseImage(4)"
              />
              <img
                :src="boxInsideImage"
                class="photo"
                v-else
                @click="viewImagesEvent(3)"
              />
              <div
                class="del-img"
                v-if="returnType(form.boxInsideImage)"
                @click="delImage(4)"
              >
                <van-icon name="cross" />
              </div>
            </div>
            <div class="prompt">电箱内照片</div>
          </div>
        </li>
        <!-- 模块位置 -->
        <li class="bg select-box">
          <van-field
            readonly
            clickable
            name="picker"
            :value="form.moduleLocationName"
            placeholder="请选择模块位置"
            :right-icon="ICON3"
            @click="rowShow = true"
          />
          <van-popup
            v-model="rowShow"
            round
            position="bottom"
            class="popup-box"
          >
            <van-picker
              show-toolbar
              :columns="rows"
              value-key="name"
              :default-index="moduleIndex"
              visible-item-count="5"
              @confirm="selectRow"
              @cancel="rowShow = false"
            />
          </van-popup>
        </li>
        <li class="camera form-item">
          <div class="camera-box">
            <!-- <van-uploader :upload-icon="ICON1" disabled /> -->
            <div class="image">
              <img
                :src="ICON1"
                alt=""
                class="img-icon"
                v-if="!returnType(form.moduleImage)"
                @click="mychooseImage(5)"
              />
              <img
                :src="moduleImage"
                class="photo"
                v-else
                @click="viewImagesEvent(4)"
              />
              <div
                class="del-img"
                v-if="returnType(form.moduleImage)"
                @click="delImage(5)"
              >
                <van-icon name="cross" />
              </div>
            </div>
            <div class="prompt">模块照片</div>
          </div>
        </li>
        <li class="form-item">
          <van-button class="submit" @click="submit">保存</van-button>
        </li>
      </ul>
    </div>
    <van-overlay :show="loading" z-index="1999" class-name="loading-overlay">
      <div class="wrapper">
        <div class="block">
          <van-loading />
        </div>
      </div>
    </van-overlay>
    <van-image-preview
      v-model="viewImageShow"
      :images="images"
      :startPosition="defaultViewIndex"
      :closeable="true"
      @change="viewChange"
    >
      <template v-slot:index>{{ viewTip }}</template>
    </van-image-preview>
  </div>
</template>

<script>
import "../../style/style.scss"
import navbar from "../../components/Navbar"
import {
  getLocationTree,
  getMacs,
  updateLocation,
  getModuleDetail,
} from "../../request/api"
import wx from "weixin-js-sdk" //引入微信js-sdk
import { Toast, ImagePreview } from "vant"
export default {
  name: "updateLocation",
  components: { navbar, [ImagePreview.Component.name]: ImagePreview.Component },
  inject: ["reload"],
  data() {
    return {
      ICON1: require("../../assets/icons/camera.png"),
      ICON2: require("../../assets/icons/select.png"),
      ICON3: require("../../assets/icons/up.png"),
      loading: false,
      type: "", //1扫一扫 2手动获取
      focusShow: false, //控制mac列表显隐
      towerShow: false,
      floorShow: false,
      roomShow: false,
      electricityShow: false,
      rowShow: false,
      macsData: [],
      macArr: [],
      inputMac: "", //input中输入的mac
      towerList: [], //大楼列表
      floorList: [], //选中大楼的楼层列表
      roomList: [], //选中楼层的配电间列表
      electricityList: [], //选中配电间的电箱列表
      rows: [
        { value: 1, name: "第一排" },
        { value: 2, name: "第二排" },
        { value: 3, name: "第三排" },
        { value: 4, name: "第四排" },
        { value: 5, name: "第五排" },
        { value: 6, name: "第六排" },
        { value: 7, name: "第七排" },
        { value: 8, name: "第八排" },
        { value: 9, name: "第九排" },
        { value: 10, name: "第十排" },
      ],
      form: {
        projectId: "", //项目id
        moduleMac: "", //mac
        towerId: "", //大楼id
        towerName: "", //大楼名称
        floorId: "", //楼层id
        floorName: "", //楼层名称
        roomId: "", //配电间id
        roomName: "", ////配电间别名
        roomNumber: "", //配电间编号（显示）
        outsideImage: "", //配电间外照片
        indoorImage: "", //配电间内照片
        electricityId: "", //电箱id
        electricityName: "", //电箱别名
        electricityNumber: "",
        boxOuterImage: "", //电箱外照片
        boxInsideImage: "", //电箱内照片
        moduleLocation: "", //模块位置，第n排
        moduleLocationName: "",
        moduleImage: "", //模块照片
      },
      outsideImage: "", //配电间外照片-base64
      indoorImage: "", //配电间内照片-base64
      boxOuterImage: "", //电箱外照片-base64
      boxInsideImage: "", //电箱内照片-base64
      moduleImage: "", //模块照片-base64
      towerIndex: "", //默认选中大楼的索引
      floorIndex: "", //默认选中楼层的索引
      roomIndex: "", //默认选中配电间的索引
      electricityIndex: "", //默认选中电箱的索引
      moduleIndex: "", //默认选中模块位置的索引
      viewImageShow: false, //查看图片
      images: [], //查看图片arr
      defaultViewIndex: 0, //默认查看索引
      viewTip: "", //查看提示
    }
  },
  watch: {
    inputMac: function() {
      if (this.inputMac) {
        let arr = []
        this.macsData.forEach(item => {
          let patt = new RegExp(this.inputMac, "i")
          let result = patt.test(item)
          if (result) {
            arr.push(item)
          }
        })
        this.macArr = arr
      } else {
        this.form.moduleMac = ""
      }
    },
    "form.moduleMac": function() {
      this.getDetail()
    },
  },
  computed: {
    returnImageUrl() {
      return function(val) {
        let str = ""
        if (val === "") {
          str = this.ICON1
        } else if (val instanceof File) {
          str = this.getImg(val)
        } else {
          str = val
        }
        return str
      }
    },
    returnImageStyle() {
      return function(val) {
        if (val === "") {
          return "img-icon"
        } else {
          return ""
        }
      }
    },
    returnType() {
      return function(val) {
        // val instanceof File
        return val ? true : false
      }
    },
  },
  created() {
    this.form.projectId = this.$route.query.projectId
    this.type = Number(this.$route.query.type)
    if (this.type === 1) {
      this.form.moduleMac = this.$route.query.mac
    }
    this.getTree()
    this.getWeiXinConfig()
  },
  mounted() {
    document.addEventListener("click", this.monitorClick)
  },
  methods: {
    getWeiXinConfig() {
      let url = window.location.href.split("#")[0]
      this.$getWXConfig(url, ["chooseImage", "getLocalImgData"])
    },
    //获取位置树
    getTree() {
      this.loading = true
      getLocationTree({ projectId: this.form.projectId })
        .then(res => {
          this.towerList = res.data.towerList
          if (this.type && this.type === 2) {
            this.getMacs()
          } else {
            this.loading = false
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    //获取模块列表
    getMacs() {
      getMacs({ projectId: this.form.projectId })
        .then(res => {
          this.loading = false
          this.macsData = res.data.items
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    //显示模块列表
    expand() {
      this.focusShow = true
      this.macArr = this.macsData
    },
    //获取模块详情
    getDetail() {
      this.loading = true
      getModuleDetail({
        projectId: this.form.projectId,
        moduleMac: this.form.moduleMac,
      })
        .then(res => {
          let {
            towerId, //大楼id
            towerName, //大楼名称
            floorId, // 楼层id
            floorName, //楼层名称
            roomId, //配电间id
            roomName, //配电间别名
            roomNumber, //配电间编号
            outsideImage, //配电间外照片
            indoorImage, //配电间内照片
            electricityId, //电箱id
            electricityNumber, //电箱编号
            electricityName, //电箱别名
            boxOuterImage, //电箱外照片
            boxInsideImage, //电箱内照片
            moduleLocation, //模块位置，第n排
            moduleImage, //模块照片
          } = res.data
          this.form.towerId = towerId //大楼id
          this.form.towerName = towerName //大楼名称
          this.form.floorId = floorId //楼层id
          this.form.floorName = floorName //楼层名称
          this.form.roomId = roomId //配电间id
          this.form.roomName = roomName ////配电间别名
          this.form.roomNumber = roomNumber //配电间编号（显示）
          this.form.electricityId = electricityId //电箱id
          this.form.electricityName = electricityName //电箱别名
          this.form.electricityNumber = electricityNumber
          if (moduleLocation) {
            this.form.moduleLocation = Number(moduleLocation) //模块位置，第n排
            this.rows.forEach((item, index) => {
              if (item.value === Number(moduleLocation)) {
                this.moduleIndex = index
                this.form.moduleLocationName = item.name
              }
            })
          }
          this.outsideImage = this.form.outsideImage = outsideImage
          this.indoorImage = this.form.indoorImage = indoorImage
          this.boxOuterImage = this.form.boxOuterImage = boxOuterImage
          this.boxInsideImage = this.form.boxInsideImage = boxInsideImage
          this.moduleImage = this.form.moduleImage = moduleImage
          let ids = [towerId, floorId, roomId, electricityId]
          this.returnIndex(ids)
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    //选中mac
    selectMac(val) {
      this.form.moduleMac = val
      this.inputMac = val
      this.focusShow = false
    },

    //选中大楼
    selectTower(value) {
      this.form.towerId = value.id
      this.form.towerName = value.name
      this.floorList = value.items
      this.towerShow = false
    },
    //点击选择楼层input
    floorEvent() {
      if (
        this.form.towerId === "" ||
        this.form.towerId === null ||
        this.form.towerId === undefined
      ) {
        this.$toast("请先选择大楼")
      } else {
        this.floorShow = true
      }
    },
    //选中楼层
    selectFloor(value) {
      this.form.floorId = value.id
      this.form.floorName = value.name
      this.roomList = value.items
      this.floorShow = false
    },
    //点击选择配电间input
    roomEvent() {
      if (
        this.form.floorId === "" ||
        this.form.floorId === null ||
        this.form.floorId === undefined
      ) {
        this.$toast("请先选择楼层")
      } else {
        this.roomShow = true
      }
    },
    //选中配电间
    selectRoom(value) {
      this.form.roomId = value.id
      this.form.roomNumber = value.alias
      this.electricityList = value.items
      this.form.roomName = value.name
      this.form.outsideImage = this.outsideImage = value.outsideImage //配电间外照片
      this.form.indoorImage = this.indoorImage = value.indoorImage //配电间内照片
      this.roomShow = false
    },
    //点击选择电箱input
    boxEvent() {
      if (
        this.form.roomId === "" ||
        this.form.roomId === null ||
        this.form.roomId === undefined
      ) {
        this.$toast("请先选择配电间")
      } else {
        this.electricityShow = true
      }
    },
    //选中电箱
    selectBox(value) {
      this.form.electricityId = value.id
      this.form.electricityNumber = value.alias
      this.form.electricityName = value.name //电箱别名
      this.form.boxOuterImage = value.boxOuterImage //电箱外照片
      this.form.boxInsideImage = value.boxInsideImage //电箱内照片
      this.electricityShow = false
    },
    //选中模块位置
    selectRow(value) {
      this.form.moduleLocation = value.value
      this.form.moduleLocationName = value.name
      this.rowShow = false
    },
    //拍照
    async mychooseImage(type) {
      let file = await this.wxChooseImage(type)
      if (type === 1) {
        this.form.outsideImage = file
      } else if (type === 2) {
        this.form.indoorImage = file
      } else if (type === 3) {
        this.form.boxOuterImage = file
      } else if (type === 4) {
        this.form.boxInsideImage = file
      } else {
        this.form.moduleImage = file
      }
    },
    //微信拍照
    wxChooseImage(type) {
      let that = this
      let imgName = {
        1: "outsideImage",
        2: "indoorImage",
        3: "boxOuterImage ",
        4: "boxInsideImage",
        5: "moduleImage",
      }[type]
      let image = ""
      return new Promise(resolve => {
        wx.chooseImage({
          count: 1, // 默认9
          sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
          sourceType: ["camera"], //'album' 可以指定来源是相册还是相机，默认二者都有
          success: function(res) {
            // let localIds = res.localIds // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
            let localId = res.localIds[0]
            wx.getLocalImgData({
              localId: localId, // 图片的localID
              success: function(res) {
                let base64Data = res.localData // localData是图片的base64数据，可以用img标签显示
                image = that.$dataURLtoFile(base64Data, imgName) //file
                // that.setImage(type, res.localData)
                let imageBase64 =
                  base64Data.indexOf("data:image") === 0
                    ? base64Data
                    : "data:image/jpeg;base64," + base64Data.replace(/\n/g, "")
                if (type === 1) {
                  that.outsideImage = imageBase64
                } else if (type === 2) {
                  that.indoorImage = imageBase64
                } else if (type === 3) {
                  that.boxOuterImage = imageBase64
                } else if (type === 4) {
                  that.boxInsideImage = imageBase64
                } else {
                  that.moduleImage = imageBase64
                }
                resolve(image)
              },
            })
          },
        })
      })
    },
    //flie 转 url
    getImg(file) {
      let url = ""
      if (window.createObjectURL !== undefined) {
        // basic
        url = window.createObjectURL(file)
      } else if (window.URL !== undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file)
      } else if (window.webkitURL !== undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file)
      }
      return url
    },
    setImage(type, base64Data) {
      // let str = "data:image/jpeg;base64," + base64Data
    },
    //查看图片
    viewImagesEvent(index) {
      this.viewTip = {
        0: "配电间外",
        1: "配电间内",
        2: "电箱外",
        3: "电箱内",
        4: "模块",
      }[index]
      let {
        outsideImage,
        indoorImage,
        boxOuterImage,
        boxInsideImage,
        moduleImage,
      } = this
      this.images = [
        outsideImage,
        indoorImage,
        boxOuterImage,
        boxInsideImage,
        moduleImage,
      ]
      this.defaultViewIndex = index
      this.viewImageShow = true
    },
    viewChange(index) {
      this.viewTip = {
        0: "配电间外",
        1: "配电间内",
        2: "电箱外",
        3: "电箱内",
        4: "模块",
      }[index]
    },
    //删除图片
    delImage(type) {
      switch (type) {
        case 1:
          this.outsideImage = ""
          this.form.outsideImage = ""
          break
        case 2:
          this.indoorImage = ""
          this.form.indoorImage = ""
          break
        case 3:
          this.boxOuterImage = ""
          this.form.boxOuterImage = ""
          break
        case 4:
          this.boxInsideImage = ""
          this.form.boxInsideImage = ""
          break
        case 5:
          this.moduleImage = ""
          this.form.moduleImage = ""
          break
        default:
          break
      }
    },
    //保存
    submit() {
      const {
        projectId,
        moduleMac,
        towerId,
        floorId,
        roomId,
        roomName,
        outsideImage,
        indoorImage,
        electricityId,
        electricityName,
        boxOuterImage,
        boxInsideImage,
        moduleLocation,
        moduleImage,
      } = this.form
      let obj = {
        towerId,
        floorId,
        roomId,
        outsideImage,
        indoorImage,
        electricityId,
        boxOuterImage,
        boxInsideImage,
        moduleLocation,
        moduleImage,
      }
      let bool = this.$formValidate(obj)
      if (bool) {
        this.loading = true
        let form = new FormData()
        form.append("projectId", projectId)
        form.append("moduleMac", moduleMac)
        form.append("towerId", towerId)
        form.append("floorId", floorId)
        form.append("roomId", roomId)
        form.append("roomName", roomName)
        form.append("electricityId", electricityId)
        form.append("electricityName", electricityName)
        form.append("moduleLocation", moduleLocation)
        form.append(
          "outsideImage",
          outsideImage instanceof File ? outsideImage : ""
        )
        form.append(
          "indoorImage",
          indoorImage instanceof File ? indoorImage : ""
        )
        form.append(
          "boxOuterImage",
          boxOuterImage instanceof File ? boxOuterImage : ""
        )
        form.append(
          "boxInsideImage",
          boxInsideImage instanceof File ? boxInsideImage : ""
        )
        form.append(
          "moduleImage",
          moduleImage instanceof File ? moduleImage : ""
        )
        updateLocation(form)
          .then(() => {
            Toast("操作成功！")
            this.loading = false
            // this.reload()
            setTimeout(() => {
              if (Number(this.type) === 1) {
                this.$router.go(-1)
              } else {
                this.reload()
              }
            }, 500)
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
      }
    },
    //返回选中位置id的索引
    returnIndex(ids) {
      var aKey = this.getCheckedKeys(this.towerList, ids, "id")
      return aKey
    },
    getCheckedKeys(data, keys, key) {
      var res = []
      recursion(data, false)
      return res
      // arr -> 树形总数据
      // keys -> getCheckedKeys获取到的选中key值
      // isChild -> 用来判断是否是子节点
      function recursion(arr, isChild) {
        var aCheck = []
        for (var i = 0; i < arr.length; i++) {
          var obj = arr[i]
          aCheck[i] = false
          if (obj.items) {
            aCheck[i] = recursion(obj.items, true) ? true : aCheck[i]
            if (aCheck[i]) {
              res.push(obj)
            }
          }
          for (var j = 0; j < keys.length; j++) {
            if (obj[key] == keys[j]) {
              aCheck[i] = true
              if (res.indexOf(obj[key]) == -1) {
                res.push(obj)
              }
              break
            }
          }
        }
        if (isChild) {
          return aCheck.indexOf(true) != -1
        }
      }
    },
    handleScroll() {
      let scrollHeight =
        document.documentElement.scrollTop || document.body.scrollTop || 0
      window.scrollTo(0, Math.max(scrollHeight - 1, 0))
    },
    monitorClick(e) {
      let arr = []
      if (Object.prototype.toString.call(e) === "[object Object]") {
        e.path.forEach(item => {
          arr.push(item.className)
        })
        if (!JSON.stringify(arr).includes("model-box")) {
          this.focusShow = false
        }
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.monitorClick)
  },
}
</script>

<style lang="scss" scoped>
.update-location {
  .mac-input {
    padding: 0 37px;
    line-height: 70px;
    background: unset;
    ::v-deep .van-field__control {
      font-size: 24px;
      color: #333333;
    }
  }
  .select-box {
    ::v-deep .van-cell {
      padding: 0 37px;
      line-height: 70px;
      background-color: unset;
      .van-field__control {
        font-size: 24px;
      }
      .van-field__right-icon .van-icon {
        font-size: 27px;
      }
    }
  }
  ::v-deep .van-cell::after {
    border-bottom: unset;
  }

  //配电间别名、电箱别名input
  .input {
    padding: 0 37px;
    line-height: 70px;
    ::v-deep .van-field__control {
      font-size: 24px;
      color: #333333;
    }
  }
  .popup-box {
    ::v-deep .van-picker__cancel,
    ::v-deep .van-picker__confirm {
      padding: 10px 20px;
      font-size: 28px;
    }
    ::v-deep .van-picker-column__item {
      font-size: 28px;
    }
  }
}
.loading-overlay {
  background-color: rgba(0, 0, 0, 0);
}
</style>
