<!--
 * @Author: mengxiangfa
 * @Date: 2021-05-19 15:43:54
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-05-21 14:13:18
 * @Description: file content
 * @FilePath: \DDDgClient\src\components\Navbar.vue
-->
<template>
  <div class="navbar">
    <img :src="ICON1" class="navbar-icon" @click="backtrack" />
    <div class="navbar-title">{{ title }}</div>
    <div class="navbar-btn">
      <slot name="btn"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navbar',
  props: ['title'],
  data() {
    return {
      ICON1: require('../assets/icons/back.png'),
    }
  },
  methods: {
    backtrack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background-color: #fff;
  z-index: 998;
  &-icon {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0%, -50%);
    width: 22px;
  }
  &-title {
    line-height: 90px;
    font-size: 32px;
    color: #333333;
    text-align: center;
  }
  &-btn {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translate(0%, -50%);
    width: 100px;
    font-size: 24px;
    color: #333333;
  }
}
</style>
