<!--
 * @Author: mengxiangfa
 * @Date: 2021-05-25 15:39:06
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-06-28 13:10:27
 * @Description: file content
 * @FilePath: \DDDgClient\src\components\MyCascader.vue
-->
<template>
  <div>
    <van-popup v-model="show" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        :options="options"
        :field-names="fieldNames"
        :closeable="false"
        @change="onChange"
      >
        <template #title>
          <div class="cascader-header">
            <span>选择位置</span>
            <van-icon name="search" @click="search" />
          </div>
        </template>
      </van-cascader>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "myCascader",
  props: {
    status: Boolean,
    type: Number,
    loacTree: Array,
  },
  data() {
    return {
      show: false,
      fieldValue: "",
      cascaderValue: "",
      fieldNames: {
        text: "name",
        value: "id",
        children: "items",
      },
      options: [],
      selectedOptions: [],
    }
  },
  watch: {
    status(val) {
      this.show = val
    },
    show(val) {
      this.$emit("update:status", val)
    },
    loacTree: {
      handler: function() {
        this.options = this.getTreeData(this.loacTree)
        // console.log(JSON.stringify())
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    onChange({ value, selectedOptions, tabIndex }) {
      this.selectedOptions = selectedOptions
      let arr = selectedOptions
      if (arr[arr.length - 1].tag === 3) {
        this.row(arr)
      } else if (arr[arr.length - 1].tag === 4) {
        this.column(arr)
      }
    },
    row(arr) {
      let tree = this.loacTree
      let indexs = []
      arr.forEach(element => {
        tree.forEach((item, index) => {
          if (element.id === item.id) {
            tree = item.items
            indexs.push(index)
          }
        })
      })
      const arr1 = [
        {
          id: 1,
          name: "第一排",
          tag: 4,
        },
        {
          id: 2,
          name: "第二排",
          tag: 4,
        },
        {
          id: 3,
          name: "第三排",
          tag: 4,
        },
        {
          id: 4,
          name: "第四排",
          tag: 4,
        },
        {
          id: 5,
          name: "第五排",
          tag: 4,
        },
        {
          id: 6,
          name: "第六排",
          tag: 4,
        },
        {
          id: 7,
          name: "第七排",
          tag: 4,
        },
        {
          id: 8,
          name: "第八排",
          tag: 4,
        },
        {
          id: 9,
          name: "第九排",
          tag: 4,
        },
        {
          id: 10,
          name: "第十排",
          tag: 4,
        },
      ]
      const arr2 = [
        {
          id: 1,
          name: "第一排",
          tag: 4,
          items: [],
        },
        {
          id: 2,
          name: "第二排",
          tag: 4,
          items: [],
        },
        {
          id: 3,
          name: "第三排",
          tag: 4,
          items: [],
        },
        {
          id: 4,
          name: "第四排",
          tag: 4,
          items: [],
        },
        {
          id: 5,
          name: "第五排",
          tag: 4,
          items: [],
        },
        {
          id: 6,
          name: "第六排",
          tag: 4,
          items: [],
        },
        {
          id: 7,
          name: "第七排",
          tag: 4,
          items: [],
        },
        {
          id: 8,
          name: "第八排",
          tag: 4,
          items: [],
        },
        {
          id: 9,
          name: "第九排",
          tag: 4,
          items: [],
        },
        {
          id: 10,
          name: "第十排",
          tag: 4,
          items: [],
        },
      ]
      let items = Number(this.type) === 1 ? arr1 : arr2
      // setTimeout(() => {

      // }, 500)
      this.$nextTick(() => {
        this.options[indexs[0]].items[indexs[1]].items[indexs[2]].items[
          indexs[3]
        ].items = items
      })
    },
    column(arr) {
      if (this.type === 2) {
        let treeData = this.loacTree
        let indexs = []
        arr.forEach(element => {
          treeData.forEach((item, index) => {
            if (element.id === item.id) {
              treeData = item.items
              indexs.push(index)
            }
          })
        })
        const items = [
          {
            id: 1,
            name: "第一列",
            tag: 5,
          },
          {
            id: 2,
            name: "第二列",
            tag: 5,
          },
          {
            id: 3,
            name: "第三列",
            tag: 5,
          },
          {
            id: 4,
            name: "第四列",
            tag: 5,
          },
          {
            id: 5,
            name: "第五列",
            tag: 5,
          },
          {
            id: 6,
            name: "第六列",
            tag: 5,
          },
          {
            id: 7,
            name: "第七列",
            tag: 5,
          },
          {
            id: 8,
            name: "第八列",
            tag: 5,
          },
          {
            id: 9,
            name: "第九列",
            tag: 5,
          },
          {
            id: 10,
            name: "第十列",
            tag: 5,
          },
          {
            id: 11,
            name: "第十一列",
            tag: 5,
          },
          {
            id: 12,
            name: "第十二列",
            tag: 5,
          },
          {
            id: 13,
            name: "第十三列",
            tag: 5,
          },
          {
            id: 14,
            name: "第十四列",
            tag: 5,
          },
          {
            id: 15,
            name: "第十五列",
            tag: 5,
          },
          {
            id: 16,
            name: "第十六列",
            tag: 5,
          },
          {
            id: 17,
            name: "第十七列",
            tag: 5,
          },
          {
            id: 18,
            name: "第十八列",
            tag: 5,
          },
          {
            id: 19,
            name: "第十九列",
            tag: 5,
          },
          {
            id: 20,
            name: "第二十列",
            tag: 5,
          },
        ]
        this.options[indexs[0]].items[indexs[1]].items[indexs[2]].items[
          indexs[3]
        ].items[indexs[4]].items = items
      }
    },
    search() {
      this.show = false
      this.$emit("serach", this.selectedOptions)
    },
    getTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        console.log(data[i].items)
        if (data[i].items) {
          if (data[i].items.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].items = undefined
          } else {
            // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].items)
          }
        }
      }
      return data
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .van-cell::after {
  border-bottom: unset;
}
::v-deep .van-cascader {
  .van-cascader__header {
    padding: 20px 40px;
    height: unset;
    .van-cascader__title {
      width: 100%;
    }
  }
  .van-cascader__tabs .van-tabs__nav--complete,
  .van-cascader__option {
    // padding: 0 40px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .van-tab {
    font-size: 24px;
    line-height: unset;
  }
  .van-tab__text {
    width: 80px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .van-tabs__line {
    width: 80px;
    background-color: #e31725;
  }
  .van-cascader__option--selected {
    color: #e31725;
  }
  .van-cascader__option {
    font-size: 24px;
    line-height: 24px;
  }
}
.cascader-header {
  display: flex;
  justify-content: space-between;
  // padding: 20px 0;
  font-size: 28px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #333333;
}
</style>
