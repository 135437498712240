<!--
 * @Author: mengxiangfa
 * @Date: 2021-05-21 10:37:59
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-06-24 14:07:23
 * @Description: file content
 * @FilePath: \DDDgClient\src\views\module\record.vue
-->
<template>
  <div class="record-box">
    <navbar title="模块更换记录"> </navbar>
    <div class="search">
      <van-field
        v-model="fieldValue"
        is-link
        readonly
        placeholder="选择位置"
        @click="selectLoactionShow = true"
      />
    </div>
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        offset="30"
        @load="onLoad"
      >
        <template #loading>
          <van-overlay
            :show="loadingShow"
            z-index="1999"
            class-name="loading-overlay"
          >
            <div class="wrapper">
              <div class="block">
                <van-loading />
              </div>
            </div>
          </van-overlay>
        </template>
        <record-item :type="1" :lables="lables" :data="recordList" />
      </van-list>
    </div>
    <!-- <select-loaction :show.sync="selectLoactionShow" :data="searchArr" /> -->
    <my-cascader
      :type="1"
      :status.sync="selectLoactionShow"
      :loacTree="searchArr"
      @serach="serach"
    />
  </div>
</template>

<script>
import "../../style/style.scss"
import { getLocationTree, getModuleChange } from "../../request/api"
import navbar from "../../components/Navbar"
import recordItem from "../../components/RecordItem"
import selectLoaction from "../../components/SelectLoaction"
import myCascader from "../../components/MyCascader"
export default {
  name: "moduleRecord",
  components: {
    navbar,
    recordItem,
    selectLoaction,
    myCascader,
  },
  data() {
    return {
      loading: false,
      finished: false,
      loadingShow: false,
      newToast: "",
      query: {
        projectId: "",
        id: "",
        moduleLocation: "",
        page: 1,
        pageSize: 10,
      },
      lables: [
        {
          lable: "模块位置",
          key: "",
        },
        {
          lable: "旧模块mac",
          key: "oldModuleMac",
        },
        {
          lable: "新模块mac",
          key: "newModuleMac",
        },
        {
          lable: "更换时间",
          key: "changeTime",
        },
        {
          lable: "更换原因",
          key: "changeReason",
        },
        {
          lable: "模块状态",
          key: "moduleStatus",
        },
      ],
      recordList: [],
      selectLoactionShow: false,
      searchArr: [],
      fieldValue: "",
    }
  },
  created() {
    this.query.projectId = this.$route.query.projectId
    this.getTree()
  },
  methods: {
    //获取位置树
    getTree() {
      getLocationTree({ projectId: this.query.projectId })
        .then(res => {
          this.searchArr = res.data.towerList
        })
        .catch(err => {
          console.log(err)
        })
    },
    onLoad() {
      this.getList()
      this.query.page++
    },
    getList() {
      this.loadingShow = true
      getModuleChange(this.query)
        .then(res => {
          let arr = res.data.items
          if (arr.length === 0) {
            this.loadingShow = false
            this.finished = true
          } else {
            this.recordList.push(...arr)
            this.loadingShow = false
          }
         
        })
        .catch(err => {
          this.loadingShow = false
        })
    },
    serach($e) {
      let arr = $e
      if (arr.length > 0) {
        this.fieldValue = arr.map(option => option.name).join("/")
        if (arr[arr.length - 1].tag === 4) {
          this.query.id = arr[arr.length - 2].id
          this.query.moduleLocation = arr[arr.length - 1].id
        }
        else {
          this.query.id = arr[arr.length - 1].id
          this.query.moduleLocation = ""
        }
      }
      this.finished = false
      this.query.page = 1
      this.getList()
    },
  },
}
</script>

<style lang="scss" scoped>
.record-box {
  padding-top: 90px;

  .search {
    margin: 30px 55px;
    width: 640px;
    height: 70px;
    border-radius: 35px;
    background: #fff;
    border: 2px solid #eaeaea;
    ::v-deep .van-cell {
      padding: 0 37px;
      line-height: 70px;
      background-color: unset;
      .van-field__control {
        font-size: 24px;
      }

      .van-cell__right-icon {
        height: 70px;
        line-height: 70px;
        font-size: 28px;
        color: #333;
      }
    }
  }
  .list {
    position: relative;
    overflow: scroll;
    // padding: 0 55px;
    width: 100%;
    height: calc(100vh - 220px);
    // overflow-y: auto;
  }
}
.loading-overlay {
  background-color: rgba(0, 0, 0, 0);
}
</style>
