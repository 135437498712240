<!--
 * @Author: mengxiangfa
 * @Date: 2021-05-19 15:20:58
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-11-15 11:39:17
 * @Description: file content
 * @FilePath: \DDDgClient\src\views\module\index.vue
-->
<template>
  <div class="change-module">
    <navbar title="模块更换">
      <template slot="btn">
        <span class="reaord" @click="getRecord">更换记录</span>
      </template>
    </navbar>
    <div class="main">
      <ul class="form">
        <li class="bg form-item">
          <van-row>
            <van-col span="6" class="title">模块位置</van-col>
            <van-col span="18" class="mac" @click="getModuleLocation">{{
              moduleLocation
            }}</van-col>
          </van-row>
        </li>
        <li class="bg form-item">
          <van-row>
            <van-col span="8" class="title">旧模块MAC</van-col>
            <van-col span="16" class="mac">{{ form.oldModuleMac }}</van-col>
          </van-row>
        </li>
        <li class="bg form-item">
          <van-row @click="getNewMac">
            <van-col span="8" class="title">新模块MAC</van-col>
            <van-col span="16" class="mac">
              {{ form.newModuleMac ? form.newModuleMac : "请扫描模块条形码" }}
              <img :src="ICON2" alt="" class="scan-icon"
            /></van-col>
          </van-row>
        </li>
        <li class="camera form-item">
          <div class="camera-box">
            <!-- <van-uploader :upload-icon="ICON1" disabled /> -->
            <div class="image">
              <img
                :src="ICON1"
                alt=""
                class="img-icon"
                v-if="!returnType(form.newModuleImage)"
                @click="mychooseImage()"
              />
              <img
                :src="newModuleImage"
                class="photo"
                v-else
                @click="viewImagesEvent()"
              />
              <div
                class="del-img"
                v-if="returnType(form.newModuleImage)"
                @click="delImage()"
              >
                <van-icon name="cross" />
              </div>
            </div>
            <div class="prompt">模块照片</div>
          </div>
        </li>

        <li class="bg select-box form-item">
          <van-field
            type="textarea"
            v-model="form.reason"
            placeholder="请填写更换原因"
            maxlength="32"
            rows="3"
            autosize
            show-word-limit
            @blur="handleScroll"
          />
        </li>
        <!-- <van-uploader :after-read="afterRead">
          <van-button icon="plus" type="primary">上传文件</van-button>
        </van-uploader> -->
        <li class="form-item">
          <van-button class="submit" @click="submit">保存</van-button>
        </li>
      </ul>
    </div>
    <van-overlay :show="loading" z-index="1999" class-name="loading-overlay">
      <div class="wrapper">
        <div class="block">
          <van-loading />
        </div>
      </div>
    </van-overlay>
    <van-image-preview
      v-model="viewImageShow"
      :images="images"
      :startPosition="defaultViewIndex"
      :closeable="true"
      @change="viewChange"
    >
      <template v-slot:index>{{ viewTip }}</template>
    </van-image-preview>
  </div>
</template>

<script>
import "../../style/style.scss"
import navbar from "../../components/Navbar"
import { getModuleDetail, updateModule } from "../../request/api"
import { Toast, ImagePreview } from "vant"
import wx from "weixin-js-sdk" //引入微信js-sdk
export default {
  name: "changeModule",
  components: { navbar, [ImagePreview.Component.name]: ImagePreview.Component },

  data() {
    return {
      ICON1: require("../../assets/icons/camera.png"),
      ICON2: require("../../assets/icons/scan.png"),
      loading: false,
      moduleLocation: "",
      form: {
        projectId: "", //项目id
        oldModuleMac: "", //旧mac
        newModuleMac: "", //新mac
        newModuleImage: "", //新模块照片
        reason: "", //更换原因
      },
      newModuleImage: "", //新模块照片-base64
      viewImageShow: false, //查看图片
      images: [], //查看图片arr
      defaultViewIndex: 0, //默认查看索引
      viewTip: "", //查看提示
    }
  },
  watch: {},
  computed: {
    returnType() {
      return function(val) {
        return val instanceof File
      }
    },
  },
  created() {
    this.form.projectId = this.$route.query.projectId
    this.form.oldModuleMac = this.$route.query.mac
    this.getModuleDetail()
    this.getWeiXinConfig()
  },
  methods: {
    getWeiXinConfig() {
      let url = window.location.href.split("#")[0]
      this.$getWXConfig(url, ["chooseImage", "getLocalImgData", "scanQRCode"])
    },
    //获取模块信息
    getModuleDetail() {
      getModuleDetail({
        projectId: this.form.projectId,
        moduleMac: this.form.oldModuleMac,
      })
        .then(res => {
          let obj = res.data
          let rowObj = {
            1: "第一排",
            2: "第二排",
            3: "第三排",
            4: "第四排",
            5: "第五排",
            6: "第六排",
            7: "第七排",
            8: "第八排",
            9: "第九排",
            10: "第十排",
            11: "第十一排",
          }[obj.moduleLocation]
          this.moduleLocation = `${obj.towerName}${obj.floorName}${obj.roomName}（${obj.roomNumber}）${obj.electricityName}（${obj.electricityNumber}）${rowObj}`
        })
        .catch(err => {
          console.log(err)
        })
    },
    getModuleLocation() {
      this.$toast(this.moduleLocation)
    },
    //更换记录
    getRecord() {
      this.$router.push({
        path: "/moduleRecord",
        query: {
          projectId: this.form.projectId,
        },
      })
    },
    //微信扫一扫 获取新的mac
    getNewMac() {
      let that = this
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["barCode"], //'qrCode', 可以指定扫二维码还是一维码，默认二者都有
        success: function(res) {
          var result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
          // console.log(result)
          let arr = result.split(",")
          let code = arr[arr.length - 1]
          if (!code || !/^[0-9a-zA-Z]*$/g.test(code)) {
            Toast("请扫描正确的模块条形码")
            return
          } else {
            that.form.newModuleMac = code
          }
        },
      })
    },
    //拍照
    async mychooseImage() {
      this.form.newModuleImage = await this.wxChooseImage()
    },
    //微信拍照
    wxChooseImage() {
      let that = this
      let image = ""
      return new Promise(resolve => {
        wx.chooseImage({
          count: 1, // 默认9
          sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
          sourceType: ["camera"], //'album' 可以指定来源是相册还是相机，默认二者都有
          success: function(res) {
            // let localIds = res.localIds // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
            let localId = res.localIds[0]
            wx.getLocalImgData({
              localId: localId, // 图片的localID
              success: function(res) {
                let base64Data = res.localData
                image = that.$dataURLtoFile(base64Data, "newModuleImage") //file
                // that.setImage(res.localData)
                if (base64Data.indexOf("data:image") === 0) {
                  that.newModuleImage = base64Data
                } else {
                  that.newModuleImage =
                    "data:image/jpeg;base64," + base64Data.replace(/\n/g, "")
                }
                resolve(image)
              },
            })
          },
        })
      })
    },
    //查看图片
    viewImagesEvent() {
      this.viewTip = "新模块照片"
      this.images = [this.newModuleImage]
      this.defaultViewIndex = 0
      this.viewImageShow = true
    },
    viewChange() {
      this.viewTip = "新模块照片"
    },
    //删除图片
    delImage() {
      this.newModuleImage = ""
      this.form.newModuleImage = ""
    },
    //保存
    submit() {
      const {
        projectId,
        oldModuleMac,
        newModuleMac,
        newModuleImage,
        reason,
      } = this.form

      let bool = this.$formValidate({
        oldModuleMac,
        newModuleMac,
        newModuleImage,
        reason,
      })
      if (bool) {
        this.loading = true
        let form = new FormData()
        form.append("projectId", projectId)
        form.append("oldModuleMac", oldModuleMac)
        form.append("newModuleMac", newModuleMac)
        form.append("newModuleImage", newModuleImage)
        form.append("reason", reason)
        updateModule(form)
          .then(res => {
            Toast("操作成功！")
            this.loading = false
            this.$router.go(-1)
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
      }
    },
    handleScroll() {
      let scrollHeight =
        document.documentElement.scrollTop || document.body.scrollTop || 0
      window.scrollTo(0, Math.max(scrollHeight - 1, 0))
    },
  },
}
</script>

<style lang="scss" scoped>
.change-module {
  .select-box {
    ::v-deep .van-cell {
      padding: 5px 37px;
      line-height: 32px;
      background-color: unset;
      .van-field__control {
        font-size: 24px;
      }
      .van-field__right-icon .van-icon {
        font-size: 27px;
      }
    }
  }
}
.loading-overlay {
  background-color: rgba(0, 0, 0, 0);
}
</style>
