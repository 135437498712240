<!--
 * @Author: mengxiangfa
 * @Date: 2021-05-24 14:11:00
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-06-24 14:05:49
 * @Description: file content
 * @FilePath: \DDDgClient\src\components\SelectLoaction.vue
-->
<template>
  <div class="cascader-box">
    <van-popup
      v-model="show"
      round
      position="bottom"
      :style="{ height: '50%' }"
    >
      <div class="header">
        <div class="title">
          <span>请选择位置</span>
          <van-icon name="search" :size="24" @click="search" />
        </div>
        <div class="values">
          <div
            :class="query[item.key] ? 'value color1' : 'value color2'"
            v-for="(item, index) in keys"
            :key="index"
          >
            {{ query[item.key] ? query[item.key] : "请选择" }}
          </div>
        </div>
      </div>
      <div class="options">
        <div
          class="options-item"
          v-for="(item, index) in arr"
          @click="query.towerId = item.id"
        >
          <span :class="isSelect(item) ? 'color3' : ''"> {{ item.name }}</span>
          <img :src="ICON1" class="options-icon" alt="" v-if="isSelect(item)" />
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "SelectLoaction",
  props: {
    show: Boolean,
    data: Array,
  },
  data() {
    return {
      ICON1: require("../assets/icons/select.png"),
      arr: [
        {
          id: "1-01",
          name: "一楼",
          tag: 1,
          items: [
            {
              id: "1-01-01",
              name: "",
              roomNumber: "1-01-01",
              outsideImage: "",
              indoorImage: "",
              tag: 2,
              items: [],
            },
            {
              id: "1-01-02",
              name: "",
              roomNumber: "1-01-02",
              outsideImage: "",
              indoorImage: "",
              tag: 2,
              items: [],
            },
          ],
        },
        {
          id: "1-02",
          name: "二楼",
          tag: 1,
          items: [
            {
              id: "1-02-01",
              name: "",
              roomNumber: "1-02-01",
              outsideImage: "",
              indoorImage: "",
              tag: 2,
              items: [],
            },
            {
              id: "1-02-02",
              name: "",
              roomNumber: "1-02-02",
              outsideImage: "",
              indoorImage: "",
              tag: 2,
              items: [],
            },
          ],
        },
      ],
      keys: [
        {
          key: "towerId",
          tag: 1,
        },
        // {
        //   key: 'floorId',
        //   tag: 2,
        // },
        // {
        //   key: 'roomId',
        //   tag: 3,
        // },
        // {
        //   key: 'electricityId',
        //   tag: 4,
        // },
        // {
        //   key: 'row',
        //   tag: '5',
        // },
      ],
      query: {
        towerId: "",
        // floorId: '',
        // roomId: '',
        // electricityId: '',
        // row: '',
      },
    }
  },
  computed: {
    isSelect: () => {
      return function(obj) {
        let bool = false
        let key = ""
        for (let i = 0; i < this.keys.length; i++) {
          const element = this.keys[i]
          if (element.tag === obj.tag) {
            key = element.key
          }
        }
        if (this.query[key] !== "" && this.query[key] === obj.id) {
          bool = true
        }
        return bool
      }
    },
  },
  created() {
    this.options = this.data
  },
  methods: {
    search() {
      console.log("搜索")
    },
  },
}
</script>

<style lang="scss" scoped>
.van-popup {
  // position: relative;
  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 168px;
    background-color: #fff;
    z-index: 2020;
    .title {
      display: flex;
      justify-content: space-between;
      padding: 20px 60px;
      width: 750px;
      font-size: 28px;
    }
    .values {
      padding: 20px 60px;
      width: 750px;
      // height: calc(100% - 88px);
      display: flex;
      justify-content: left;
      .value {
        flex: 0 0 80px;
        // height: 40px;
      }
    }
  }
}
.options {
  margin-top: 168px;
  padding: 0 60px;

  max-height: calc(100% - 180px);
  overflow-y: auto;
  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    font-size: 24px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
  }
  &-icon {
    width: 23px;
  }
}
.color1 {
  color: #333333;
}
.color2 {
  color: #999999;
}
.color3 {
  color: #e31725;
}
</style>
