<!--
 * @Author: mengxiangfa
 * @Date: 2021-10-25 13:31:07
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-10-28 17:39:10
 * @Description: file content
 * @FilePath: \DDDgClient\src\views\test\fx.vue
-->
<template>
  <div>
    <button @click="fx">分享</button>
  </div>
</template>

<script>
import wx from "weixin-js-sdk" //引入微信js-sdk

export default {
  name: "",
  data() {
    return {}
  },
  created() {
    this.getWeiXinConfig()
  },
  methods: {
    getWeiXinConfig() {
      let url = window.location.href.split("#")[0]
      this.$getWXConfig(url, [
        "checkJsApi",
        "updateAppMessageShareData",
        "updateTimelineShareData",
      ])
    },
    fx() {
      // wx.ready(function() {
      //需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({
        title: "测试11", // 分享标题
        desc: "1111111111111", // 分享描述
        link: window.location.href.split("#")[0], // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: "1", // 分享图标
        success: function(res) {
          // 设置成功
          console.log(res)
        },
        fail: function(res) {
          //alert();
          console.log(JSON.stringify(res))
        },
      })
      // // })
    },
  },
}
</script>

<style lang="scss" scoped></style>
