<!--
 * @Author: mengxiangfa
 * @Date: 2021-05-19 15:21:22
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-11-02 17:50:00
 * @Description: file content
 * @FilePath: \DDDgClient\src\views\switch\index.vue
-->
<template>
  <div class="change-switch">
    <navbar title="开关更换">
      <template slot="btn">
        <span class="reaord" @click="getRecord">更换记录</span>
      </template>
    </navbar>
    <div class="main">
      <ul class="form">
        <!-- 模块mac -->
        <li class="bg form-item" v-if="Number(type) === 1">
          <!-- 扫描识别 -->
          <van-row>
            <van-col span="6" class="title">模块MAC</van-col>
            <van-col span="18" class="mac">{{ form.moduleMac }}</van-col>
          </van-row>
        </li>
        <li
          class="form-item model-box"
          :class="focusShow ? 'bg2' : 'bg'"
          v-else
        >
          <!-- 手动选择 -->
          <van-field
            v-model="inputMac"
            placeholder="请选择模块mac"
            @focus="expand"
            class="mac-input"
          />
          <div class="macs" v-if="focusShow">
            <div
              v-for="(item, index) in macArr"
              :key="index"
              class="mac-item"
              @click="selectMac(item)"
            >
              <span :class="form.moduleMac === item ? 'color' : ''">{{
                item
              }}</span>
              <img :src="ICON1" alt="" v-if="form.moduleMac === item" />
            </div>
            <div class="mac-prompt" v-if="inputMac && macArr.length === 0">
              暂无匹配项
            </div>
            <div
              class="mac-prompt"
              v-if="macArr.length === 0 && inputMac === ''"
            >
              暂无数据
            </div>
          </div>
        </li>
        <li class="bg form-item">
          <van-row>
            <van-col span="6" class="title">模块位置</van-col>
            <van-col span="18" class="mac" @click="viewModuleLocation">{{
              moduleLocation ? moduleLocation : "-"
            }}</van-col>
          </van-row>
        </li>
        <li class="bg switch-box  form-item">
          <van-row>
            <van-col span="6" class="title">开关位置</van-col>
            <van-col span="18" class="mac">
              <van-field
                readonly
                clickable
                name="picker"
                :value="switchLoction"
                placeholder="请选择开关位置"
                :right-icon="ICON3"
                @click="selectSwitch"
            /></van-col>
          </van-row>
          <van-popup
            v-model="switchShow"
            round
            position="bottom"
            class="popup-box"
          >
            <van-picker
              show-toolbar
              :columns="columns"
              value-key="name"
              visible-item-count="5"
              @confirm="selectTower"
              @cancel="switchShow = false"
              @change="onChange"
            />
          </van-popup>
        </li>
        <li class="bg form-item">
          <van-row>
            <van-col span="6" class="title">线路地址</van-col>
            <van-col span="18" class="mac">{{
              switchLine ? switchLine : "-"
            }}</van-col>
          </van-row>
        </li>
        <li class="bg form-item">
          <van-row>
            <van-col span="6" class="title">开关名称</van-col>
            <van-col span="18" class="mac">{{
              switchName ? switchName : "-"
            }}</van-col>
          </van-row>
        </li>
        <li class="bg select-box  form-item">
          <van-field
            type="textarea"
            v-model="form.changeReason"
            placeholder="请填写更换原因"
            show-word-limit
            maxlength="32"
            rows="3"
            autosize
            @blur="handleScroll"
          />
        </li>
        <li class="form-item">
          <van-button class="submit" @click="submit">保存</van-button>
        </li>
      </ul>
    </div>
    <van-overlay :show="loading" z-index="1999" class-name="loading-overlay">
      <div class="wrapper">
        <div class="block">
          <van-loading />
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import "../../style/style.scss"
import navbar from "../../components/Navbar"
import {
  getMacs,
  getModuleDetail,
  getBreakerDetail,
  updateBreaker,
} from "../../request/api"
import { Toast } from "vant"
let cities = {}
export default {
  name: "changeSwitch",
  components: { navbar },
  inject: ["reload"],
  data() {
    return {
      ICON1: require("../../assets/icons/select.png"),
      ICON3: require("../../assets/icons/up.png"),
      loading: false,
      focusShow: false,
      switchShow: false,
      type: "",
      macsData: [],
      columns: [],
      macArr: [],
      inputMac: "", //input中输入的mac
      moduleLocation: "", //模块位置
      switchLoction: "", //开关位置
      switchName: "",
      switchLine: "",
      form: {
        projectId: "", //项目id
        moduleMac: "", //mac
        row: "", //行
        column: "", //列
        changeReason: "", //更换原因
      },
    }
  },
  watch: {
    inputMac: function() {
      if (this.inputMac) {
        let arr = []
        this.macsData.forEach(item => {
          let patt = new RegExp(this.inputMac, "i")
          let result = patt.test(item)
          if (result) {
            arr.push(item)
          }
        })
        this.macArr = arr
      } else {
        this.form.moduleMac = ""
      }
    },
  },
  computed: {
    returnImageUrl() {
      return function(val) {
        let str = ""
        if (val === "") {
          str = this.ICON1
        } else if (val instanceof File) {
          str = this.getImg(val)
          console.log(str)
        } else {
          str = val
        }
        return str
      }
    },
    returnImageStyle() {
      return function(val) {
        if (val === "") {
          return "img-icon"
        } else {
          return ""
        }
      }
    },
  },
  created() {
    this.form.projectId = this.$route.query.projectId
    this.type = this.$route.query.type
    // this.getModuleDetail()
    this.getMacs()
  },
  mounted() {
    document.addEventListener("click", this.monitorClick)
  },
  methods: {
    //获取模块列表
    getMacs() {
      this.loading = true
      getMacs({ projectId: this.form.projectId })
        .then(res => {
          this.macsData = res.data.items
          if (this.$route.query.mac && Number(this.type) === 1) {
            this.form.moduleMac = this.$route.query.mac
            this.getModuleDetail(this.$route.query.mac)
          } else {
            this.loading = false
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    //获取模块信息
    getModuleDetail(val) {
      this.loading = true
      getModuleDetail({
        projectId: this.form.projectId,
        moduleMac: val,
      })
        .then(res => {
          this.loading = false
          let obj = res.data
          // {
          //   towerId: "1",
          //   towerName: "创智谷A座",
          //   floorId: "1-01",
          //   floorName: "一楼",
          //   roomId: "1-01-01",
          //   roomName: "配电间01",
          //   roomNumber: "1-01-01",
          //   electricityId: "1-01-01-01",
          //   electricityNumber: "1-01-01-01",
          //   electricityName: "电箱01",
          //   moduleLocation: 3,
          //   moduleImage: "",
          // }
          this.columns.push({ values: res.data.rows, defaultIndex: 0 })
          this.columns.push({
            values: res.data.rows[0].columns,
            defaultIndex: 0,
          })
          let newObj = {}
          res.data.rows.forEach(item => {
            newObj[item.value] = item.columns
          })
          cities = newObj
          let rowObj = {
            1: "第一排",
            2: "第二排",
            3: "第三排",
            4: "第四排",
            5: "第五排",
            6: "第六排",
            7: "第七排",
            8: "第八排",
            9: "第九排",
            10: "第十排",
            11: "第十一排",
          }[obj.moduleLocation]
          this.moduleLocation = `${obj.towerName}${obj.floorName}${obj.roomName}（${obj.roomNumber}）${obj.electricityName}（${obj.electricityNumber}）${rowObj}`
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    //查看模块位置信息
    viewModuleLocation() {
      if (this.moduleLocation) {
        this.$toast(this.moduleLocation)
      }
    },
    //选择开关位置弹窗
    selectSwitch() {
      if (this.form.moduleMac) {
        this.switchShow = true
      } else {
        Toast("请先选择模块！")
      }
    },
    //获取开关信息
    getBreakerDetail() {
      const { projectId, moduleMac, row, column } = this.form
      getBreakerDetail({
        projectId,
        moduleMac,
        row,
        column,
      })
        .then(res => {
          let obj = res.data
          this.switchName = obj.breakerName ? obj.breakerName : "暂无名称"
          this.switchLine = obj.breakerLine
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    //更换记录
    getRecord() {
      this.$router.push({
        path: "/switchRecord",
        query: {
          projectId: this.form.projectId,
        },
      })
    },
    //显示模块列表
    expand() {
      this.focusShow = true
      this.macArr = this.macsData
    },
    //选中mac
    selectMac(val) {
      this.form.moduleMac = val
      this.inputMac = val
      this.focusShow = false
      this.loading = true
      this.getModuleDetail(val)
    },
    onChange(picker, values) {
      picker.setColumnValues(1, cities[values[0].value])
    },
    selectTower(value) {
      this.switchLoction = value[0].name + value[1].name
      this.form.row = value[0].value
      this.form.column = value[1].value
      this.switchShow = false
      this.loading = true
      this.getBreakerDetail()
    },
    //保存
    submit() {
      let bool = this.$formValidate(this.form)
      if (bool) {
        this.loading = true
        updateBreaker(this.form)
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            Toast("操作成功！")
            this.loading = false
            if (Number(this.type) === 1) {
              this.$router.go(-1)
            } else {
              this.reload()
            }
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
      }
    },
    handleScroll() {
      let scrollHeight =
        document.documentElement.scrollTop || document.body.scrollTop || 0
      window.scrollTo(0, Math.max(scrollHeight - 1, 0))
    },
    monitorClick(e) {
      let arr = []
      e.path.forEach(item => {
        arr.push(item.className)
      })
      if (!JSON.stringify(arr).includes("model-box")) {
        this.focusShow = false
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.monitorClick)
  },
}
</script>

<style lang="scss" scoped>
.change-switch {
  //mac搜索框
  .mac-input {
    padding: 0 37px;
    line-height: 70px;
    background: unset;
    ::v-deep .van-field__control {
      font-size: 24px;
      color: #333333;
    }
  }
  .select-box {
    ::v-deep .van-cell {
      padding: 5px 37px;
      line-height: 32px;
      background-color: unset;
      .van-field__control {
        font-size: 24px;
      }
      .van-field__right-icon .van-icon {
        font-size: 27px;
      }
    }
  }
  ::v-deep .van-cell::after {
    border-bottom: unset;
  }

  .switch-box {
    ::v-deep .van-cell {
      padding: 0;
      line-height: 70px;
      background-color: unset;
      .van-field__control {
        text-align: right;
        font-size: 24px;
      }
      .van-field__right-icon .van-icon {
        font-size: 25px;
      }
    }
  }
  //弹窗选择框
  .popup-box {
    ::v-deep .van-picker__cancel,
    ::v-deep .van-picker__confirm {
      padding: 10px 20px;
      font-size: 28px;
    }
    ::v-deep .van-picker-column__item {
      font-size: 28px;
    }
  }
}
.loading-overlay {
  background-color: rgba(0, 0, 0, 0);
}
</style>
