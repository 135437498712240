<!--
 * @Author: mengxiangfa
 * @Date: 2021-04-20 15:33:33
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-11-09 10:06:16
 * @Description: file content
 * @FilePath: \DDDgClient\src\views\login.vue
-->
<template>
  <div class="login-box">
    <div class="login-form">
      <img :src="LOGO" alt="" class="logo" />
      <van-form @submit="handleLogin">
        <van-field
          :left-icon="ICON1"
          v-model="username"
          type="tel"
          maxlength="11"
          placeholder="请输入账号"
          :rules="[{ required: true, message: '请输入账号' }]"
          @blur="handleScroll"
        />
        <van-field
          :left-icon="ICON2"
          v-model="password"
          type="password"
          placeholder="请输入密码"
          :rules="[{ required: true, message: '请输入密码' }]"
          maxlength="20"
          @blur="handleScroll"
        />
        <div class="btn">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            :disabled="returnDisabled"
            >登录</van-button
          >
        </div>
      </van-form>
    </div>
    <van-overlay :show="loading" z-index="1999" class-name="loading-overlay">
      <div class="wrapper">
        <div class="block">
          <van-loading />
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Toast } from "vant"
import "../style/style.scss"
export default {
  data() {
    return {
      LOGO: require("../assets/image/logo.png"),
      ICON1: require("../assets/icons/user_name.png"),
      ICON2: require("../assets/icons/password.png"),
      username: "", //13866669999
      password: "", //888888
      loading: false,
    }
  },
  computed: {
    returnDisabled: function() {
      let bool = true
      if (this.username && this.password.length >= 6) {
        bool = false
      }
      return bool
    },
  },
  methods: {
    //登录
    handleLogin() {
      if (!/^1[3-9][0-9]{9}$/.test(this.username)) {
        Toast({
          message: "输入账号无效，请确认后重试！",
          getContainer: () => {
            return document.querySelector(".login-form")
          },
        })
      } else {
        this.loading = true
        this.$store
          .dispatch("login", {
            username: this.username,
            password: this.password,
          })
          .then(res => {
            this.loading = false
            this.$router.push("/home")
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
      }
    },
    handleScroll() {
      let scrollHeight =
        document.documentElement.scrollTop || document.body.scrollTop || 0
      window.scrollTo(0, Math.max(scrollHeight - 1, 0))
    },
  },
}
</script>

<style lang="scss" scoped>
.login-box {
  padding-top: 336px;
}
.login-form {
  position: relative;
  margin: 0 auto;
  width: 666px;
  height: 600px;
  background: #ffffff;
  border-radius: 15px;
  text-align: center;

  .logo {
    margin-top: 62px;
    margin-bottom: 70px;
    width: 61px;
  }
  ::v-deep .van-cell {
    margin: 20px 45px;
    width: unset;
    line-height: 40px;
    .van-field__left-icon .van-icon {
      font-size: 40px;
    }
    .van-field__control {
      color: #333;
      font-size: 24px;
    }
    .van-field__error-message {
      display: none;
    }
  }
  ::v-deep .van-cell::after {
    border-bottom: 2px solid #e0dddd;
  }

  .btn {
    ::v-deep .van-button {
      margin: 68px auto;
      width: 490px;
      height: 90px;
      background-color: #e31725;
      border: 2px solid #e31725;
      font-size: 32px;
      font-family: Source Han Sans CN;
    }
  }
}
.loading-overlay {
  background-color: rgba(0, 0, 0, 0);
}
.van-toast {
  top: 46%;
}
</style>
