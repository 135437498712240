<!--
 * @Author: mengxiangfa
 * @Date: 2021-05-24 09:19:35
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-05-24 13:43:22
 * @Description: file content
 * @FilePath: \DDDgClient\src\components\RecordItem.vue
-->
<template>
  <div class="records-box">
    <div
      :class="index > 0 ? 'record-box  top-margin' : 'record-box'"
      v-for="(item, index) in data"
      :key="index"
    >
      <div
        :class="i < lables.length - 1 ? 'record  border' : 'record'"
        v-for="(val, i) in lables"
        :key="i"
      >
        <div class="lable">
          {{ val.lable }}
        </div>
        <div class="value">
          <!-- {{ val.key ? `${item[val.key]}` :  }} -->
          <span v-if="val.key">
            <span v-if="val.key === 'moduleStatus'">{{
              item[val.key] === 1 ? '异常' : '正常'
            }}</span>
            <span v-else-if="val.key === 'breakerStatus'">{{
              item[val.key] === 1 ? '在线' : '离线'
            }}</span>
            <span v-else>{{ item[val.key] }}</span>
          </span>
          <span v-else>{{
            type === 1 ? returnModuleLocation(item) : returnSwitchLocation(item)
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecordItem',
  props: {
    type: Number, //1 模块记录 2开关记录
    lables: Array,
    data: Array,
  },
  data() {
    return {}
  },
  computed: {
    returnModuleLocation: () => {
      return function(obj) {
        let rowObj = {
          1: '第一排',
          2: '第二排',
          3: '第三排',
          4: '第四排',
          5: '第五排',
          6: '第六排',
          7: '第七排',
          8: '第八排',
          9: '第九排',
          10: '第十排',
          11: '第十一排',
        }[obj.moduleLocation]
        let str = `${obj.towerName}${obj.floorName}${
          obj.roomName ? obj.roomName : `（${obj.roomNumber}）`
        }${
          obj.electricityName
            ? obj.electricityName
            : `（${obj.electricityNumber}）`
        }${rowObj}`
        return str
      }
    },
    returnSwitchLocation: () => {
      return function(obj) {
        let rowObj = {
          1: '第一排',
          2: '第二排',
          3: '第三排',
          4: '第四排',
          5: '第五排',
          6: '第六排',
          7: '第七排',
          8: '第八排',
          9: '第九排',
          10: '第十排',
          11: '第十一排',
        }[obj.breakerRow]
        let columnObj = {
          1: '第一列',
          2: '第二列',
          3: '第三列',
          4: '第四列',
          5: '第五列',
          6: '第六列',
          7: '第七列',
          8: '第八列',
          9: '第九列',
          10: '第十列',
          11: '第十一列',
          12: '第十二列',
          13: '第十三列',
          14: '第十四列',
          15: '第十五列',
          16: '第十六列',
          17: '第十七列',
          18: '第十八列',
          19: '第十九列',
          20: '第二十列',
          21: '第二十一列',
          22: '第二十二列',
        }[obj.breakerColumn]
        let str = `${obj.towerName}${obj.floorName}${
          obj.roomName ? obj.roomName : `（${obj.roomNumber}）`
        }${
          obj.electricityName
            ? obj.electricityName
            : `（${obj.electricityNumber}）`
        }${rowObj}${columnObj}`
        return str
      }
    },
  },
  created() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.records-box {
  margin: 0 55px;
  font-size: 24px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  .record-box {
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    .record {
      padding: 0 35px;
      display: flex;
      justify-content: space-between;
      line-height: 70px;
      color: #333333;
      .lable {
        flex: 0 0 130px;
      }
      .value {
        color: #999;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .top-margin {
    margin-top: 30px;
  }
  .border {
    border-bottom: 2px solid #dddddd;
  }
}
</style>
